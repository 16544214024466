import React from "react";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import { fontFG46m, fontFG20l } from "../../styles/fonts";
import CTAButton from "../shared/CTAButton";
import { jobBoardLink } from "../../config";

const Title = styled.h2`
  ${fontFG46m};
  margin-bottom: 24px;
`;

const Brief = styled.div`
  ${fontFG20l};
  margin-bottom: 20px;
`;

const TextWrapper = styled.div``;

const Button = styled(CTAButton)``;

const ButtonWrapper = styled.div`
  margin: 24px 0 80px 0;
  ${Button} {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 26px auto 120px auto;
    ${Button} {
      width: auto;
    }
  }
`;

const Opening = styled.div``;

const Container = styled(Grid)`
  padding: 90px 0;

  @media (min-width: ${breakpoints.lg}) {
    padding: 120px 0;

    ${TextWrapper} {
      grid-column: 2 / span 8;
    }
    ${ButtonWrapper} {
      grid-column: 11 / span 3;
    }
    ${Opening} {
      grid-column: 2 / span 12;
    }
  }
`;

const OpeningSection = ({ title, brief, buttonText }) => {
  return (
    <Container id="openings">
      <TextWrapper>
        <Title>{title}</Title>
        <Brief>{brief}</Brief>
      </TextWrapper>
      <ButtonWrapper>
        <Button href={jobBoardLink} target="_blank">
          {buttonText}
        </Button>
      </ButtonWrapper>
      <Opening
        dangerouslySetInnerHTML={{
          __html: `<iframe height="600" width="100%" src=${jobBoardLink}></iframe>`,
        }}
      />
    </Container>
  );
};

export default OpeningSection;
