import React, { Component } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import HeaderSection from "../components/careers/Header";
import BenefitsSection from "../components/careers/BenefitsSection";
import OpeningsSection from "../components/careers/OpeningSection";

class CareersPage extends Component {
  // componentDidMount() {
  //   window.analytics.page("Careers");
  // }

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;
    const pageData = data.prismicCareerPage.data;
    return (
      <Layout location={location} locale={locale} pageData={pageData}>
        <Seo
          title={`${pageData.header_title} | Workmate`}
          description={pageData.header_brief}
          image={pageData.header_banner_image.url}
        />
        <HeaderSection
          title={pageData.header_title}
          brief={pageData.header_brief}
          bannerImage={pageData.header_banner_image}
        />
        <OpeningsSection
          title={pageData.openings_title}
          brief={pageData.openings_brief}
          buttonText={pageData.button_text}
        />
        <BenefitsSection
          benefits={pageData.benefits}
          bannerImage={pageData.benefits_banner_image}
          interimImage={pageData.benefits_interim_image}
        />
      </Layout>
    );
  }
}

export default CareersPage;

export const query = graphql`
  query CareerQuery($localeKey: String) {
    prismicCareerPage(lang: { eq: $localeKey }) {
      data {
        navigation_cta_button_text
        navigation_cta_button_url
        header_title
        header_brief
        header_banner_image {
          alt
          gatsbyImageData
        }
        benefits {
          title
          brief
        }
        benefits_banner_image {
          alt
          gatsbyImageData
        }
        benefits_interim_image {
          alt
          gatsbyImageData
        }
        openings_title
        openings_brief
        button_text
      }
    }
  }
`;
