import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { fontFG30m, fontR14r } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import Grid from "../../styles/Grid";

const Title = styled.h2`
  ${fontFG30m};
`;

const Brief = styled.div`
  ${fontR14r}
`;

const Image = styled.div``;

const Banner = styled.div``;

const TextContainer = styled.div`
  ${Title} {
    margin-bottom: 24px;
  }
`;

const Benefit = styled.div`
  ${TextContainer} {
    margin-bottom: 60px;

    @media (min-width: ${breakpoints.lg}) {
      ${TextContainer} {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const Benefits = styled(Grid)`
  ${Image} {
    order: 2;
  }
  ${Benefit} {
    order: 1;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${Image} {
      order: 1;
      grid-column: 2 / span 6;
    }
    ${Benefit} {
      margin: 90px 0;
      grid-column: 8 / span 6;
    }
  }
`;

const Container = styled(Grid)`
  ${Banner} {
    grid-column: 2 / span 4;
  }
  ${Benefits} {
    grid-column: 1 / span 6;
    margin: 90px 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Banner} {
      grid-column: 2 / span 12;
    }
    ${Benefits} {
      grid-column: 1 / span 14;
      margin: 120px 0;
    }
  }
`;

const BenefitsSection = ({ benefits, bannerImage, interimImage }) => {
  const bannerImageData = getImage(bannerImage);
  const interimImageData = getImage(interimImage);
  return (
    <Container>
      {bannerImageData && (
        <Banner>
          <GatsbyImage image={bannerImageData} alt={bannerImage.alt || ""} />
        </Banner>
      )}
      <Benefits>
        {interimImageData && (
          <Image>
            <GatsbyImage
              image={interimImageData}
              alt={interimImage.alt || ""}
            />
          </Image>
        )}
        <Benefit>
          {benefits.map((benefit, index) => (
            <TextContainer key={index}>
              <Title>{benefit.title}</Title>
              <Brief>{benefit.brief}</Brief>
            </TextContainer>
          ))}
        </Benefit>
      </Benefits>
    </Container>
  );
};

export default BenefitsSection;
