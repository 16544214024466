import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import { fontFG52m, fontFG20l } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";

const Title = styled.h1`
  ${fontFG52m};
`;

const Brief = styled.div`
  ${fontFG20l};
`;

const Image = styled.div``;

const Container = styled(Grid)`
  padding: 90px 0 0;

  ${Image} {
    grid-column: 2 / span 4;
  }

  ${Title} {
    text-align: center;
    margin-bottom: 30px;
  }

  ${Brief} {
    text-align: center;
    margin-bottom: 80px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 120px 0 0;

    ${Title} {
      grid-column: 5 / span 6;
    }

    ${Brief} {
      grid-column: 5 / span 6;
    }

    ${Image} {
      grid-column: 2 / span 12;
    }
  }
`;

const Header = ({ title, brief, bannerImage }) => {
  const bannerImageData = getImage(bannerImage);
  return (
    <Container>
      <Title>{title}</Title>
      <Brief>{brief}</Brief>
      {bannerImageData && (
        <Image>
          <GatsbyImage image={bannerImageData} alt={bannerImage.alt || ""} />
        </Image>
      )}
    </Container>
  );
};

export default Header;
